import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LegalText } from 'src/definitions/legal-text.enum';
import { UserRole } from 'src/definitions/user-role.enum';
import { AuthGuardAdmin } from 'src/shared-components/ng-login/auth/auth-admin.guard';
import { AuthGuardClient } from 'src/shared-components/ng-login/auth/auth-client.guard';
import { AuthGuardLogged } from 'src/shared-components/ng-login/auth/auth-logged.guard';
import { AuthGuardOperator } from 'src/shared-components/ng-login/auth/auth-operator.guard';
import { RENT_BUS_EN, RENT_BUS_ES, URLPROVINCES } from 'src/utils/constants';
import { SelectivePreloadingStrategy } from 'src/utils/selective-preloading-strategy';

const provinceRoutes = URLPROVINCES.flatMap((province) => [
  {
    path: `${RENT_BUS_ES}-${province}`,
    loadComponent: () =>
      import('./client/buses-provinces-list/buses-province-base/buses-province-base.component').then(
        (m) => m.BusesProvinceBaseComponent
      ),
    data: {
      province: province
    }
  },
  {
    path: `${RENT_BUS_EN}-${province}`,
    loadComponent: () =>
      import('./client/buses-provinces-list/buses-province-base/buses-province-base.component').then(
        (m) => m.BusesProvinceBaseComponent
      ),
    data: {
      province: province
    }
  }
]);

const routes: Routes = [
  // Operator routes for logged users
  {
    path: 'operator',
    loadComponent: () => import('./operator/operator.component').then((m) => m.OperatorComponent),
    data: {
      role: UserRole.OPERATOR
    },
    canActivate: [AuthGuardOperator],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./operator/operator-home/operator-home.component').then((m) => m.OperatorHomeComponent)
      },
      {
        path: 'budget-parameter',
        loadComponent: () =>
          import('./operator/budget-parameter/budget-parameter.component').then((m) => m.BudgetParameterComponent)
      },
      {
        path: 'detail',
        loadComponent: () =>
          import('./operator/operator-details/operator-details.component').then((m) => m.OperatorDetailsComponent)
      },
      {
        path: 'fleet',
        loadComponent: () => import('./operator/fleet/fleet.component').then((m) => m.FleetComponent)
      },
      {
        path: 'seasons',
        loadComponent: () =>
          import('./operator/season/season-list/season-list.component').then((m) => m.SeasonListComponent)
      },
      {
        path: 'vehicles/:id',
        loadComponent: () =>
          import('./operator/vehicle-details/vehicle-details.component').then((m) => m.VehicleDetailsComponent)
      },
      {
        path: 'reservations',
        loadComponent: () =>
          import('./shared-components/client-reservations/client-reservations.component').then(
            (m) => m.ClientReservationsComponent
          ),
        data: {
          role: UserRole.OPERATOR
        }
      },
      {
        path: 'reservations/:id',
        loadComponent: () =>
          import('./operator/reservation-details/reservation-details.component').then(
            (m) => m.ReservationDetailsComponent
          ),
        data: {
          role: UserRole.OPERATOR
        }
      },
      {
        path: 'clients',
        loadComponent: () => import('./operator/client-list/client-list.component').then((m) => m.ClientListComponent)
      },
      {
        path: 'clients/reviews',
        loadComponent: () =>
          import('./shared-components/client-reviews/client-reviews.component').then((m) => m.ClientReviewsComponent),
        data: {
          role: UserRole.OPERATOR
        }
      },
      {
        path: 'clients/:client_id',
        loadComponent: () =>
          import('./operator/client-details/client-details.component').then((m) => m.ClientDetailsComponent),
        data: {
          role: UserRole.OPERATOR
        }
      },
      {
        path: 'help',
        loadComponent: () => import('./operator/help-page/help-page.component').then((m) => m.HelpPageComponent)
      },
      {
        path: 'help/:category',
        loadComponent: () =>
          import('./operator/help-page/questions/questions.component').then((m) => m.QuestionsComponent)
      },
      {
        path: 'require-desktop',
        loadComponent: () =>
          import('./operator/require-desktop/require-desktop.component').then((m) => m.RequireDesktopComponent)
      }
    ]
  },
  // Operator routes for non-authorised users
  {
    path: 'operator',
    loadComponent: () => import('./auth-container/auth-container.component').then((m) => m.AuthContainerComponent),
    canActivate: [AuthGuardLogged],
    data: {
      role: UserRole.OPERATOR
    },
    children: [
      {
        path: 'login',
        loadComponent: () => import('../shared-components/ng-login/login.component').then((m) => m.LoginComponent),
        data: {
          role: UserRole.OPERATOR
        }
      },
      {
        path: 'forgot-password',
        loadComponent: () =>
          import('./auth-container/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent)
      },
      {
        path: 'reset-password/:token',
        loadComponent: () =>
          import('./auth-container/reset-password/reset-password.component').then((m) => m.ResetPasswordComponent),
        data: {
          role: UserRole.OPERATOR
        }
      }
    ]
  },
  // Admin routes for non-authorised users
  {
    path: 'admin',
    loadComponent: () => import('./auth-container/auth-container.component').then((m) => m.AuthContainerComponent),
    canActivate: [AuthGuardLogged],
    data: {
      role: UserRole.ADMIN
    },
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        loadComponent: () => import('../shared-components/ng-login/login.component').then((m) => m.LoginComponent),
        data: {
          role: UserRole.ADMIN
        }
      }
    ]
  },
  // Admin routes for admins
  {
    // TODO: Change this route to the component that contains all admin components
    path: 'admin',
    loadComponent: () => import('./admin/admin.component').then((m) => m.AdminComponent),
    canActivate: [AuthGuardAdmin],
    data: {
      role: UserRole.ADMIN
    },
    children: [
      {
        path: '',
        redirectTo: 'operators',
        pathMatch: 'full'
      },
      {
        path: 'operators',
        loadComponent: () =>
          import('./admin/operator-list/operator-list.component').then((m) => m.OperatorListComponent)
      },
      {
        path: 'operator/:operator_id',
        loadComponent: () =>
          import('./admin/admin-operator-details/admin-operator-details.component').then(
            (m) => m.AdminOperatorDetailsComponent
          ),
        data: {
          role: UserRole.ADMIN
        }
      },
      {
        path: 'clients',
        loadComponent: () =>
          import('./admin/admin-client-list/admin-client-list.component').then((m) => m.AdminClientListComponent)
      },
      {
        path: 'clients/reviews/:client_id',
        loadComponent: () =>
          import('./shared-components/client-reviews/client-reviews.component').then((m) => m.ClientReviewsComponent),
        data: {
          role: UserRole.ADMIN
        }
      },
      {
        path: 'operator/reviews/:operator_id',
        loadComponent: () =>
          import('./shared-components/client-reviews/client-reviews.component').then((m) => m.ClientReviewsComponent),
        data: {
          role: UserRole.ADMIN
        }
      },
      {
        path: 'reservations',
        loadComponent: () =>
          import('./shared-components/client-reservations/client-reservations.component').then(
            (m) => m.ClientReservationsComponent
          ),
        data: {
          role: UserRole.ADMIN
        }
      },
      {
        path: 'reservations/:id',
        loadComponent: () =>
          import('./operator/reservation-details/reservation-details.component').then(
            (m) => m.ReservationDetailsComponent
          ),
        data: {
          role: UserRole.ADMIN
        }
      },
      {
        path: 'clients/:client_id',
        loadComponent: () =>
          import('./operator/client-details/client-details.component').then((m) => m.ClientDetailsComponent),
        data: {
          role: UserRole.ADMIN
        }
      },
      {
        path: 'reservations/operator/:operatorId',
        loadComponent: () =>
          import('./shared-components/client-reservations/client-reservations.component').then(
            (m) => m.ClientReservationsComponent
          ),
        data: {
          role: UserRole.ADMIN
        }
      },
      {
        path: 'reservations/client/:clientId',
        loadComponent: () =>
          import('./shared-components/client-reservations/client-reservations.component').then(
            (m) => m.ClientReservationsComponent
          ),
        data: {
          role: UserRole.ADMIN
        }
      },
      {
        path: 'billing',
        loadComponent: () => import('./admin/billing/billing.component').then((m) => m.BillingComponent),
        data: {
          role: UserRole.ADMIN,
          preload: true
        }
      },
      {
        path: 'configuration',
        loadComponent: () =>
          import('./admin/admin-configuration/admin-configuration.component').then((m) => m.AdminConfigurationComponent)
      }
    ]
  },
  // Operator sign up (outside of operator routes in order to avoid rendering top navbar)
  {
    path: 'operator/signup',
    loadComponent: () =>
      import('./auth-container/operator-sign-up/operator-sign-up.component').then((m) => m.OperatorSignUpComponent)
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./auth-container/forgot-password-client/forgot-password-client.component').then(
        (m) => m.ForgotPasswordClientComponent
      )
  },
  {
    path: 'reset-password/:token',
    loadComponent: () =>
      import('./auth-container/reset-password-client/reset-password-client.component').then(
        (m) => m.ResetPasswordClientComponent
      ),
    data: {
      role: UserRole.CLIENT
    }
  },
  {
    path: 'operator/awaiting-approval',
    loadComponent: () =>
      import('./operator/awaiting-approval/awaiting-approval.component').then((m) => m.AwaitingApprovalComponent)
  },
  // Client routes for non-authorised users
  {
    path: '',
    loadComponent: () => import('./client/client.component').then((m) => m.ClientComponent),
    data: {
      role: UserRole.CLIENT
    },
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadComponent: () => import('./client/home/home.component').then((m) => m.HomeComponent)
      },
      {
        path: 'results',
        loadComponent: () =>
          import('./client/client-planning-results/client-planning-results.component').then(
            (m) => m.ClientPlanningResultsComponent
          )
      },
      {
        path: 'book-seat',
        loadComponent: () =>
          import('./client/individual-reservation-confirmed/individual-reservation-confirmed.component').then(
            (m) => m.IndividualReservationConfirmedComponent
          )
      },
      {
        path: 'budget-result',
        loadComponent: () =>
          import('./client/budget-details/budget-details.component').then((m) => m.BudgetDetailsComponent)
      },
      {
        path: 'confirm-reservation',
        loadComponent: () =>
          import('./client/reservation-confirmation/reservation-confirmation.component').then(
            (m) => m.ReservationConfirmationComponent
          )
      },
      {
        path: 'operator/:microsite',
        loadComponent: () =>
          import('./operator/operator-microsite/operator-microsite.component').then((m) => m.OperatorMicrositeComponent)
      },
      {
        path: 'legal-notice',
        loadComponent: () =>
          import('./shared-components/legal-base/legal-base.component').then((m) => m.LegalBaseComponent),
        data: {
          textType: LegalText.LEGALNOTICE
        }
      },
      {
        path: 'privacy',
        loadComponent: () =>
          import('./shared-components/legal-base/legal-base.component').then((m) => m.LegalBaseComponent),
        data: {
          textType: LegalText.PRIVACY
        }
      },
      {
        path: 'cookies',
        loadComponent: () =>
          import('./shared-components/legal-base/legal-base.component').then((m) => m.LegalBaseComponent),
        data: {
          textType: LegalText.COOKIES
        }
      },
      {
        path: 'terms-and-conditions',
        loadComponent: () =>
          import('./shared-components/legal-base/legal-base.component').then((m) => m.LegalBaseComponent),
        data: {
          textType: LegalText.TERMS
        }
      },
      {
        path: 'terms-and-conditions-operator',
        loadComponent: () =>
          import('./shared-components/legal-base/legal-base.component').then((m) => m.LegalBaseComponent),
        data: {
          textType: LegalText.TERMS,
          operator: true
        }
      },
      {
        path: 'contact',
        loadComponent: () =>
          import('./shared-components/contact-form/contact-form.component').then((m) => m.ContactFormComponent)
      },
      {
        path: 'faqs',
        loadComponent: () =>
          import('./shared-components/unregistered-user-faqs/unregistered-user-faqs.component').then(
            (m) => m.UnregisteredUserFaqsComponent
          )
      },
      {
        path: 'faqs/:userType',
        loadComponent: () =>
          import('./shared-components/unregistered-user-faqs/faqs/faqs.component').then((m) => m.FaqsComponent)
      },
      {
        path: 'buses-provinces',
        loadComponent: () =>
          import('./client/buses-provinces-list/buses-provinces-list.component').then(
            (m) => m.BusesProvincesListComponent
          )
      },
      ...provinceRoutes
    ]
  },
  // Client routes for logged users
  {
    path: '',
    loadComponent: () => import('./client/client.component').then((m) => m.ClientComponent),
    data: {
      role: UserRole.CLIENT
    },
    canActivate: [AuthGuardClient],
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'profile',
        loadComponent: () =>
          import('./client/client-profile/client-profile.component').then((m) => m.ClientProfileComponent)
      },
      {
        path: 'client/reservations',
        loadComponent: () =>
          import('./client/client-reservation/client-reservation-list.component').then(
            (m) => m.ClientReservationListComponent
          )
      },
      {
        path: 'client/reservations/:id',
        loadComponent: () =>
          import('./client/client-reservation-details/client-reservation-details.component').then(
            (m) => m.ClientReservationDetailsComponent
          )
      },
      {
        path: 'client/reviews',
        loadComponent: () =>
          import('./client/client-reviews-list/client-reviews-list.component').then((m) => m.ClientReviewsListComponent)
      },
      {
        path: 'client/help',
        loadComponent: () =>
          import('./client/client-help-page/client-help-page.component').then((m) => m.ClientHelpPageComponent)
      }
    ]
  },
  // UI Guide
  {
    path: 'ui-guide',
    loadComponent: () => import('./ui-guide/ui-guide.component').then((m) => m.UIGuideComponent)
  },
  // Client Login
  {
    path: 'login',
    loadComponent: () =>
      import('../shared-components/ng-login/login-client/login-client.component').then((m) => m.LoginClientComponent),
    data: {
      role: UserRole.CLIENT
    },
    canActivate: [AuthGuardLogged]
  },
  // Client Sign Up
  {
    path: 'signup',
    loadComponent: () =>
      import('./auth-container/client-sign-up/client-sign-up.component').then((m) => m.ClientSignUpComponent),
    data: {
      role: UserRole.CLIENT
    },
    canActivate: [AuthGuardLogged]
  },
  // Confirm Sign Up
  {
    path: 'confirm-signup/:role/:token',
    loadComponent: () =>
      import('./auth-container/confirm-signup/confirm-signup.component').then((m) => m.ConfirmSignupComponent)
  },
  // Unblock Account
  {
    path: 'unblock-account/:role/:token',
    loadComponent: () =>
      import('./auth-container/unblock-account/unblock-account.component').then((m) => m.UnblockAccountComponent)
  },
  // Expired account activation token
  {
    path: 'expired-token/:role/:token',
    loadComponent: () =>
      import('./auth-container/expired-activation-link/expired-activation-link.component').then(
        (m) => m.ExpiredActivationLinkComponent
      )
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      preloadingStrategy: SelectivePreloadingStrategy,
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule],
  providers: [SelectivePreloadingStrategy]
})
export class AppRoutingModule {}
